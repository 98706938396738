<template>
    <div class="w3-container" style="padding-top: 40px;padding-bottom:16px; margin-top:40px" v-bind:style="'height:'+  (screenHeight-40) +'px;'">
        <Toolbar  
            :click-add="showAdd"
            :click-edit="showEdit"
            :click-delete="showDelete"
            :click-refresh="showRefresh"
            :set-filter="loadDataSearch"
            :status-print="false"
            :data   = "recs"
            :fields = "json_fields"
            type    = "xls"
            name    = "Target.xls"
        />
        <div class="w3-container" style="padding: 0px;">
            <div id="wrapper">
                <div id="content">
                    <div class="w3-col l6 m6 s12">
                        <div class="w3-hide-small w3-hide-medium" id="boundary">
                            <table id="header" cellpadding="4" cellspacing="0" border="0" style="width:100%;">
                                <thead>
                                    <tr class="w3-theme-l2">
                                        <th width="50px">No. </th>
                                        <th v-for="fl in filterByShow" :width="fl.width" :key="fl.name" class="">{{fl.name}}</th>
                                        <th width="1px"></th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-165) +'px;'">
                            <table class="w3-table w3-table-all">
                                <tr v-for="(dt, index) in recs" :key="dt.tg_id" class="w3-hover-pale-blue w3-small cursor">
                                    <td class="w3-hide-large" v-on:click="setKlikRow(index, dt)">
                                        <div class="w3-col s4 m2 w3-theme-l5">
                                            <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>Target
                                        </div>
                                        <div class="w3-col s8 m10 w3-theme-l4">
                                            : {{dt.tg_total | toRp}}
                                        </div>
                                        <div class="w3-col s4 m2 w3-theme-l5">Realisasi</div>
                                        <div class="w3-col s8 m10 w3-theme-l4">
                                            : {{dt.tg_realisasi | toRp}}
                                        </div>
                                        <div class="w3-col s4 m2 w3-theme-l5">Y-M</div>
                                        <div class="w3-col s8 m10 w3-theme-l4">
                                            : {{dt.tg_date | moment("YYYY-MM")}}
                                        </div>
                                    </td>
                                    <td width="50px" class="w3-hide-small w3-hide-medium w3-border-right">
                                        <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>&nbsp;{{dt.idx}}
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :width="findObjFind('width','tg_id')" class="w3-hide-small w3-hide-medium w3-border-right">
                                        {{dt.tg_id}}
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','tg_total')" class="w3-hide-small w3-hide-medium w3-border-right" style="text-align:right">
                                        {{dt.tg_total | toRp}}
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','tg_realisasi')" class="w3-hide-small w3-hide-medium w3-border-right" style="text-align:right">
                                        {{dt.tg_realisasi | toRp}}
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','tg_date')" class="w3-hide-small w3-hide-medium w3-center">
                                        {{dt.tg_date | moment("YYYY-MM")}}
                                    </td>
                                </tr>                                            
                            </table>
                        </div>
                    </div>
                    <div class="w3-col l6 m6 s12">
                        <header class="w3-container w3-round w3-border w3-border-black w3-center">
                            <h4 style="text-transform: uppercase;">PROGRESS SALES</h4>
                        </header>
                        <div class="w3-row" v-for="(dtl, index) in recDetails" :key="index">
                            <span>{{dtl.ts_name}}</span>
                            <span class="w3-right">{{ dtl.tgd_total | toRp }}</span>
                            <div class="w3-theme-l3 w3-round-large">
                                <div class="w3-container w3-theme-d5 w3-center w3-round-large" style="height: 23px; max-width:100%" v-bind:style="'width:' + getPercen(dtl.tgd_realisasi, dtl.tgd_total)">
                                <span class="w3-left" style="position: absolute;color: black;">{{dtl.tgd_realisasi | toRp}} / {{getPercen(dtl.tgd_realisasi, dtl.tgd_total)}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- pagging -->
        <div style="height: 35px;bottom: 25px;">
            <div class="w3-bar w3-center w3-theme" style="height: 38px;position: absolute;bottom: 0;left: 0px;">
                <a :class="pages.halaman == 1 ?'dsb':''" class="w3-bar-item w3-button" @click="first()" href="#">First</a>
                <a :class="pages.halaman == 1 ?'dsb':''" class="w3-bar-item w3-button" @click="prev()" href="#">«</a>
                <a class="w3-bar-item w3-button w3-theme-l4" href="#">Page {{pages.halaman}} sd {{pages.ttlhalaman}}</a>
                <a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="w3-bar-item w3-button" @click="next()" href="#">»</a>
                <a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="w3-bar-item w3-button" @click="last()" href="#">Last</a>
                <a class="w3-bar-item w3-button w3-border-left w3-right" href="#">Rec {{pages.ttlrec}}</a>
            </div>
        </div>
        <div id="modal-form" class="w3-modal scrl-y">
            <div class="w3-modal-content w3-animate-zoom w3-card-4 w3-round-large" style="max-width:600px;top: -40px;">
            <header class="w3-container w3-theme-d3 round-top-large"> 
                <span onclick="document.getElementById('modal-form').style.display='none'" 
                class="w3-button w3-display-topright w3-round-large">&times;</span>
                <h5><i class="fa fa-windows"></i>&nbsp; Add / Edit data</h5>
            </header>
            <div class="w3-container">
                <form class="w3-container" id="frmGdg" v-on:submit.prevent="saveData" autocomplete="off">
                    <div class="w3-row" style="margin-top:5px;">
                        <div class="w3-col l3 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                            <label for="tg_id">{{findObjFind('name','tg_id')}} :</label>
                        </div>
                        <div class="w3-col l5 m7 s12">
                            <input ref="tg_id" readonly type="text" class="w3-input w3-small w3-round-large" id="tg_id" :placeholder="findObjFind('name','tg_id')" v-model="rec.tg_id">
                        </div>
                    </div>
                    <div class="w3-row" style="margin-top:5px;">
                        <div class="w3-col l3 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                            <label for="tg_total">{{findObjFind('name','tg_total')}} :</label>
                        </div>
                        <div class="w3-col l5 m7 s12">
                            <money id="tg_total" class="w3-input w3-right-align w3-round-large" v-model="rec.tg_total" ref="search"  v-bind="money"/>
                        </div>
                    </div>
                    <div class="w3-row" style="margin-top:5px;">
                        <div class="w3-col l3 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                            <label for="tg_date">
                                Periode :
                            </label>
                        </div>
                        <div class="w3-col l5 m7 s10" style="position: relative;">
                            <input required type="text" class="w3-input w3-small w3-round-large" id="tg_date" :placeholder="findObjFind('name','tg_date')" v-model="rec.tg_date">
                            <transition name="calendar-fade">
                                <date-picker @close="showdate.tg_date = false" v-if="showdate.tg_date" v-model="rec.tg_date"></date-picker>
                            </transition>
                            <button v-on:click="showdate.tg_date=true" type="button" class="w3-btn w3-small w3-round-large w3-white" style="position: absolute; top: 0px; right: 0px;"><i class="fa fa-calendar"></i></button>
                        </div>
                    </div>
                    <div class="w3-row">
                        <table class="w3-table-all w3-margin-top" style="margin-bottom: 5px;">
                            <thead>
                                <tr class="">
                                    <th class="w3-border-right" width="30px">No</th>
                                    <th class="w3-border-right">Sales</th>
                                    <th class="w3-border-right w3-center" width="120px">Total</th>
                                    <th width="30px">
                                        <button v-on:click="addData" type="button" title="Tambah Item" class="w3-btn w3-tiny w3-blue w3-round-large" >
                                            <i class="fa fa-plus"></i>
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="w3-small">
                                <tr v-for="(dtl, index) in recDetails" :key="index" v-show="dtl.pfunction!='delete'">
                                    <td style="padding-top:15px">{{ index + 1 }}</td>
                                    <td class="w3-border-left w3-small">
                                        <model-list-select
                                            :list="recsSales"
                                            class="w3-small"
                                            option-value="ts_id"
                                            option-text="ts_name"
                                            v-model="dtl.tgd_sales"
                                            v-on:input="setSales(dtl.tgd_sales)"
                                            return-object
                                            placeholder="sales">
                                        </model-list-select>
                                    </td>
                                    <td class="w3-border-left">
                                        <money class="w3-input w3-small w3-right-align" v-model="dtl.tgd_total" v-bind="money"/>
                                    </td>
                                    <td class="w3-border-left">
                                        <button v-on:click="removeDetail(index, dtl)" type="button" class="w3-btn w3-tiny w3-red w3-round-large">
                                            <i class="fa fa-remove"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                </form>
            </div>
            <footer class="w3-container w3-theme-d1"  style="margin-top: 15px;">
                <h6 class="w3-right">
                <button form="frmGdg" class="w3-small w3-btn w3-ripple w3-white w3-round" type="submit"><i class="fa fa-save"></i> Save</button>
                <button type="button" class="w3-small w3-btn w3-ripple w3-red w3-round" onclick="document.getElementById('modal-form').style.display='none'"><i class="fa fa-close"></i> Close</button>
                </h6>
            </footer>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import Config from '@/conf/Config.js';
import axios from "axios";
import "vue-search-select/dist/VueSearchSelect.css";
import Toolbar from "@/components/Toolbar.vue";
import moment from "moment";
import {Money} from 'v-money';
import { ModelListSelect } from "vue-search-select";
import DatePicker from "@/components/DatePicker.vue";

export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'Target',
    components: {
        Toolbar,
        Money,
        DatePicker,
        ModelListSelect
    },
    data() {
        return {
            screenHeight : 0,
            selectedRow : -1,
            DatePickerFormat: 'YYYY-MM-DD',
            showdate :{tg_date:false},
            rec: {},
            recs: [],
            recDetails : [],
            recsSales : [],
            pages : {halaman : 1, batas : 0, ttlhalaman : 0, ttlrec:0, limit:Config.perpage, search:''},
            filters : {
            	operator : 'AND',
                fields :[
                        {fieldFind : '', key : 'tg_id', type: 'text', name : 'ID', filter : true, width : '100px', grid : true},
                        {fieldFind : '', key : 'tg_total', type: 'text', name : 'Target', filter : true, width : '150px', grid : true},
                        {fieldFind : '', key : 'tg_realisasi', type: 'text', name : 'Realisasi', filter : true, width : '150px', grid : true},
                        {fieldFind : '', key : 'tg_date', type: 'text', name : 'Y-M', filter : true, width : 'auto', grid : true},
                        // {fieldFind:'', key : 'tg_date', type: 'select', name : 'Status', filter : true, width : '80px', grid : true, valueFind: [{key:'T',label:'Aktif'},{key:'F',label:'Tidak Aktif'}]},
                ],
                find : []   
            },
            money: {"decimal": ",", "thousands": ".", "prefix": "", "suffix": "", "precision": 0, "masked": false},
            /**Untuk transfer excel*/
            json_fields : {
                'ID' : 'g_id',
                'NAME' : 'g_name',
                'KETERANGAN' : 'g_keterangan'
            },
        }
    },
    methods : {
        findObjFind(key, val){
            return this.findObj(this.filters.fields,'key',val)[key];
        },
        findField(val){
            return this.findObj(this.fieldRules, 'field',val);
        },
        findObj(obj, key, value) {
            let ketemu = 0;
            for (var pkey in obj) {
                if (obj[pkey][key] == value) {
                    break;
                } else {
                    ketemu++;
                }
            }
            if(obj[ketemu]!=undefined){
                return obj[ketemu];
            }else
                return null;
        },
        first(){
            let self = this;
            self.pages.halaman = 1;
            self.loadData();
        },
        prev(){
            let self = this;
            self.pages.halaman = self.pages.halaman - 1;
            self.loadData();
        },
        next(){
            let self = this;
            self.pages.halaman = self.pages.halaman + 1;
            self.loadData();
        },
        last(){
            let self = this;
            self.pages.halaman = self.pages.ttlhalaman;
            self.loadData();
        },
        setKlikRow(index, dt){
            let self = this;
            self.selectedRow = index;
            self.rec = Object.assign({}, dt);
            self.loadDetail();
        },
        loadData(){
            this.screenHeight = window.innerHeight;
            let self = this;
            self.recDetails = [];
            self.selectedRow = -1;
            self.rec = {};
            let params = {
                pfunction:'show',
                limit: self.pages.limit,
                page: self.pages.halaman,
                search: self.pages.search
            }
            axios.post("sales/ApiTarget.php",params).then(function (response) {
                let idx = (self.pages.limit*self.pages.halaman)-self.pages.limit+1;
                self.pages.ttlrec = parseFloat(response.data.ttlrec);
                self.pages.ttlhalaman = Math.ceil(parseFloat(response.data.ttlrec)/self.pages.limit);
                response.data.rec.map(function(val){
                    val['idx'] = idx;
                    idx++;
                });
                self.recs = response.data.rec;
            });
        },
        async loadDetail(){
            let self = this;
            var vparams = {
                pfunction : 'showbyid',
                tg_id : self.rec.tg_id
            };
            axios.post("sales/ApiTargetDetail.php",vparams).then(function (response) {
                if(response.data.success==true){
                    self.recDetails = response.data.rec;
                }else{
                    console.log(response.data.messages);
                }
            });
        },
        showRefresh(){
            this.pages.search = "";
            this.loadData();
        },
        loadDataSearch(filter){
            this.pages.search = filter;
            this.loadData();
        },
        async showAdd() {
            let self = this;
            self.recDetails = [];
            self.stsAdd = true;
            let key = await axios.post("admin/ApiGetId.php",{
                pfunction : 'getId',
                vc_prefix : 'TRG',
                vc_table : 'tb_target'
            });
            self.rec = {
                tg_id : key.data,
                tg_total : 0,
                tg_realisasi : 0,
                tg_date : moment(new Date()).format('YYYY-MM-DD'),
            };
            document.getElementById("modal-form").style.display = "block";
            setTimeout(function(){
                document.getElementById('tg_total').focus();
            },500);
        },
        async addData() {
            let self = this;
            let key = await axios.post("admin/ApiGetId.php",{
                pfunction : 'getId',
                vc_prefix : 'TGD',
                vc_table : 'tb_targetdetail'
            });
            self.recDetails.push({
                tgd_id : key.data,
                tg_id : self.rec.tg_id,
                tgd_sales : '',
                tgd_total : self.getSisa(),
                tgd_date : moment(String(self.rec.tg_date)).format("YYYY-MM-DD"),
                pfunction : 'add',
                userentry : localStorage.username
            });
        },
        showEdit(){
            let self = this;
            if (self.selectedRow < 0) {
                self.$toast.error('pilih target.!');
                return false;
            }else{
                self.stsAdd = false;
                document.getElementById('modal-form').style.display='block';
                setTimeout(function(){
                    document.getElementById('tg_total').focus();
                },500);
            }
        },
        saveData(event){
            var self = this;
            if (self.stsAdd == true) {
                self.rec.pfunction = "add";
            }else{
                self.rec.pfunction = "edit";
            }
            self.rec['userentry'] = localStorage.username;
            console.log(self.rec);
            axios.post("sales/ApiTarget.php",self.rec).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    self.saveDetail();
                    if (self.stsAdd) {
                        self.recs.splice(0, 0, self.rec);
                        self.recDetails = [];
                    }else{
                        self.recs[self.selectedRow] = self.rec;
                        self.selectedRow = -1;
                        self.recDetails = [];
                    }
                    document.getElementById('modal-form').style.display='none';
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
            event.preventDefault();
            return false;
        },
        saveDetail() {
            let self = this;
            self.recDetails.map(function(key) {
                axios.post("sales/ApiTargetDetail.php",key).then(function (response) {
                    if(response.data.success == true){
                        console.log("Sukses", response.data.messages);
                    } else {
                        console.log("Gagal", response.data.messages);
                    }
                });
            });
        },
        showDelete() {
            var self = this;
            if (self.selectedRow < 0) {
                this.$toast.error('pilih target.!');
                return false;
            }else{
                swal(
                    {
                        title: "Yakin Ingin Menghapus Data ..!",
                        text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Ya, Hapus Data",
                        cancelButtonText: "Tidak, Batal Hapus",
                        closeOnConfirm: false,
                        closeOnCancel: true,
                    },
                    function(isConfirm) {
                        if (isConfirm) {
                            axios.post("sales/ApiTargetDetail.php",{
                                pfunction : 'deleteall',
                                tg_id : self.rec.tg_id
                            }).then(function (response) {
                                if(response.data.success == true){
                                    self.removeTarget();
                                } else {
                                    self.removeTarget();
                                }
                            });
                        }
                    }
                );
            }
        },
        removeDetail(idx, dtl) {
            let self = this;
            swal(
                {
                    title: "Yakin Ingin Menghapus Data ..!",
                    text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Ya, Hapus Data",
                    cancelButtonText: "Tidak, Batal Hapus",
                    closeOnConfirm: false,
                    closeOnCancel: true,
                },
                function(isConfirm) {
                    if (isConfirm) {
                        dtl.pfunction = 'delete';
                        swal({title: "Hapus..!",text: "Data Sudah terhapus",timer: 1000,type: "success"});
                    }
                }
            );
        },
        removeTarget(){
            let self = this;
            var vparams = { 
                tg_id : self.rec.tg_id,
                pfunction : "delete"
            };
            axios.post("sales/ApiTarget.php",vparams).then(function (response) {
                if (response.data.success == true) {
                    swal({title: "Hapus..!",text: "Data Sudah terhapus",timer: 1000,type: "success"});
                    self.$delete(self.recs, self.selectedRow);
                }else{
                    swal({title: "Delete",text: "Gagal delete data",type: "error",html: true});
                }
            });
        },
        getPercen(a, b){
            let percentage = (a * 100 / b).toFixed(2) + '%';
            return percentage;
        },
        getSisa(){
            let self = this;
            self.changeGrandTotal();
            return self.rec.tg_sisa;
        },
        changeGrandTotal() {
            let self = this;
            let total = 0;
            self.recDetails.map(function(value) {
               total = value.tgd_total + total;
            });
            self.rec.tg_sisa = parseFloat(self.rec.tg_total) - total;
        },
        setSales(e){
            let self = this;
            self.changeGrandTotal();
            let ketemu = 0;
            self.recDetails.map(function(key) {
                if (key.tgd_sales == e) {
                    ketemu = ketemu+1;
                }
            });
            if (ketemu > 1) {
                swal({
                    title: "Rejected !",
                    text: "Double sales",
                    type: "warning",
                });
                self.rec.sales = ketemu;
                return false;
            }
        },
        setStatus(dt) {
            if (dt === "T") {
                return "<div class='w3-green w3-center w3-round' style='width:50px;margin-top:5px;'>Active</div>";
            } else {
                return "<div class='w3-red w3-center w3-round' style='width:50px;margin-top:5px;'>In Active<div>";
            }
        },
        setInit() {
            let self = this;
            axios.post("master/ApiSales.php",{
                pfunction : 'showall'
            }).then(function (response) {
                self.recsSales = response.data.rec;
            });
        }
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
        pNumber(num) {
            var x = num.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/);
            return !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '' + x[3] : '');
        }
    },
    watch: {
        // 'moveCenter': function(){
        //     console.log("OK");
        // }
    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;                
            });
        }
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.setInit();
        this.loadData();
    }
};
</script>